
import { Component, Vue } from 'vue-property-decorator';
import Page from '@/components/Page.vue'
import Sticky from '@/components/Sticky.vue'
import Rules from '@/utils/rules';
import emailjs from '@emailjs/browser';

@Component({
  components: {
    Page,
    Sticky,
  }
})
export default class ContactView extends Vue {

  formIsValid = false
  sent = false
  sending = false
  rules: any = {}
  form = {
    name: '',
    email: '',
    subject: '',
    message: '',
  }

  get canSend(): boolean {
    return this.formIsValid && !this.sending;
  }

  onSubmitForm() {
    const form = this.$refs.form as any;
    if (form && form.validate()) {
      this.sending = true;
      emailjs.send('service_c8wbton', 'template_tenp93l', this.form, 'user_O18QK960ffy3YYMZ5ALjb')
        .then(() => {
          this.sending = false;
          this.sent = true;
        }, () => {
          this.sending = false;
        });
    }

  }

  created() {
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
      email: (value: string) => Rules.email(value) || this.$t('rules.email'),
    };
  }
}
