
import { Component, Vue } from 'vue-property-decorator'
import Sticky from '@/components/Sticky.vue'
import Page from '@/components/Page.vue'

@Component({
  components: {
    Sticky,
    Page,
  }
})
export default class FAQView extends Vue {
  items = [
    { key: 'method', icon: 'mdi-book-open-page-variant' },
    { key: 'twinning', icon: 'mdi-account-group-outline' },
    { key: 'invoices', icon: 'mdi-cash-usd-outline' },
    { key: 'remote', icon: 'mdi-skype-business' },
    { key: 'onSite', icon: 'mdi-bus' },
    { key: 'cancellations', icon: 'mdi-close-circle-outline' },
    { key: 'levels', icon: 'mdi-tag-multiple' },
    { key: 'timeSlots', icon: 'mdi-calendar' },
    { key: 'materials', icon: 'mdi-book-multiple' },
    { key: 'fluid', icon: 'mdi-human-greeting' },
    { key: 'children', icon: 'mdi-human-child' },
  ];
}
