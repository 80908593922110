import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ServicesView from '../views/ServicesView.vue'
import FAQView from '../views/FAQView.vue'
import TryoutView from '../views/TryoutView.vue'
import TestimonialsView from '../views/TestimonialsView.vue'
import ContactView from '../views/ContactView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', name: 'home', component: HomeView },
  { path: '/services', name: 'services', component: ServicesView },
  { path: '/testimonials', name: 'testimonials', component: TestimonialsView },
  { path: '/faq', name: 'faq', component: FAQView },
  { path: '/tryout', name: 'tryout', component: TryoutView },
  { path: '/contact', name: 'contact', component: ContactView },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
