
import { Vue, Component, Watch } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import { availableLanguages, links, socialLinks } from '@/global';
import { calendlyLink } from '@/global'

@Component({
  components: {
    Sidebar,
  }
})
export default class HomeView extends Vue {
  drawer = false
  links = links
  socialLinks = socialLinks
  availableLanguages = availableLanguages
  calendlyLink = calendlyLink

  @Watch('$i18n.locale')
  onI18nLocaleChange(locale: string) {
    localStorage.setItem('locale', locale);
  }

  onAppBarNavClick() {
    this.drawer = !this.drawer;
  }
}
