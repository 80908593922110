
import { Component, Vue } from 'vue-property-decorator';
import Sticky from '@/components/Sticky.vue';
import Page from '@/components/Page.vue'
import { calendlyLink } from '@/global'

@Component({
  components: {
    Sticky,
    Page,
  }
})
export default class TryoutView extends Vue {
  calendlyLink = calendlyLink
}
