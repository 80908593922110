
import { Vue, Component, VModel } from 'vue-property-decorator';
import { availableLanguages, links } from '@/global';

@Component
export default class Sidebar extends Vue {
  @VModel({ type: Boolean, default: false }) opened!: boolean

  links = links
  year = new Date().getFullYear()
  availableLanguages = availableLanguages
}
