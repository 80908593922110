
import { Component, Vue } from 'vue-property-decorator';
import Sticky from '@/components/Sticky.vue';
import Page from '@/components/Page.vue'
import Papa from 'papaparse';

interface ITestimonial {
  expand: boolean,
  name: string,
  comment: string,
  attitude: number,
  clarity: number,
  knowledge: number,
  flexibility: number,
  material: number,
  method: number,
  organization: number,
  average: number,
  company: string | null,
}

@Component({
  components: {
    Sticky,
    Page,
  }
})
export default class TestimonialsView extends Vue {

  videos: any[] = [
    { label: 'Emma Westenberg', file: '/videos/emma.mp4', link: 'https://www.linkedin.com/in/emma-westenberg-12922b1a5/', },
  ]
  loaded = false
  items: Array<ITestimonial> = []
  limit = 300
  clients = [
    // '/clients/bourse-montreal.png',
    // '/clients/desjardins.png',
    // '/clients/fizz.png',
    // '/clients/intact-assurance.gif',
    // '/clients/loto-quebec.png',
    // '/clients/optimum-assurance.png',
    // '/clients/radio-canada.png',
  ]

  get reversedTestimonials(): Array<ITestimonial> {
    return this.items.reverse();
  }

  onShowMoreLess(testimonial: ITestimonial): void {
    testimonial.expand = !testimonial.expand;
    this.$nextTick(this.redrawMasonry);
  }

  redrawMasonry(): void {
    // @ts-ignore
    this.$redrawVueMasonry('testimonials');
  }

  parse(): void {
    Papa.parse('/testimonials.csv', {
      download: true,
      header: true,
      skipEmptyLines: false,
      complete: (results: any) => {
        this.loaded = true;
        this.items = results.data.map((item: any) => ({
          expand: false,
          name: item['Nom complet'],
          company: item['Organisation'],
          comment: item['Témoignage'],
          average: (
            parseInt(item['Méthode'])
            + parseInt(item['Connaissances'])
            + parseInt(item['Clarté'])
            + parseInt(item['Organisation_1'])
            + parseInt(item['Flexibilité'])
            + parseInt(item['Attitude'])
            + parseInt(item['Matériel'])
          ) / 7
        })).filter((item: any) => !!item.comment && item.average > 3);

        this.$nextTick(this.redrawMasonry);
      }
    });
  }

  created() {
    this.parse();
  }
}
